import React, { useState, useEffect } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import api from "../../config/api";

function CadastroUsuarioPanel() {
    const [usuarios, setUsuarios] = useState([]);
    const [selectedUser, setSelectedUser] = useState("Novo Usuário");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [roles, setRoles] = useState([]);
    const [availableRoles, setAvailableRoles] = useState([]);
    const [message, setMessage] = useState({ text: "", type: "" });
    const [loading, setLoading] = useState(false);
    const [empresaId, setEmpresaId] = useState(null);

    const navigate = useNavigate();

    // Buscar dados iniciais (usuários, roles e empresaId)
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);

                // Recuperar o empresaId do localStorage
                const empresaIdFromStorage = localStorage.getItem("empresaId");
                if (!empresaIdFromStorage) {
                    throw new Error("Empresa não encontrada no localStorage.");
                }
                setEmpresaId(empresaIdFromStorage);

                // Obter lista de roles
                const rolesResponse = await api.getRoles();
                if (rolesResponse && Array.isArray(rolesResponse)) {
                    setAvailableRoles(rolesResponse);
                } else {
                    throw new Error("Erro ao carregar roles.");
                }

                // Obter lista de usuários com base no empresaId
                const usersResponse = await api.get("/main/users", {
                    params: { empresa_id: empresaIdFromStorage },
                });

                if (usersResponse.data && Array.isArray(usersResponse.data)) {
                    setUsuarios(
                        usersResponse.data.sort((a, b) =>
                            a.username.localeCompare(b.username)
                        )
                    );
                } else {
                    throw new Error("Erro ao carregar usuários.");
                }
            } catch (error) {
                console.error("Erro ao carregar dados iniciais:", error);
                setMessage({
                    text: error.response?.data?.message || "Erro ao carregar dados iniciais.",
                    type: "danger",
                });
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    // Buscar detalhes do usuário selecionado
    useEffect(() => {
        const fetchUserDetails = async () => {
            if (selectedUser !== "Novo Usuário") {
                try {
                    const response = await api.get(`/main/userDetails/${selectedUser}`);
                    if (response.data) {
                        const { username = "", email = "", roles = [] } = response.data;

                        // Mapeia roles corretamente
                        const parsedRoles = Array.isArray(roles)
                            ? roles.map((role) => (typeof role === "object" && role.id ? role.id : role))
                            : [];

                        setUsername(username);
                        setEmail(email);
                        setRoles(parsedRoles);
                    } else {
                        throw new Error("Dados do usuário não encontrados.");
                    }
                } catch (error) {
                    console.error("Erro ao buscar detalhes do usuário:", error);
                    setMessage({
                        text: `Erro ao buscar detalhes do usuário: ${
                            error.response?.data?.message || "Erro desconhecido"
                        }`,
                        type: "danger",
                    });
                }
            } else {
                // Limpar campos ao selecionar "Novo Usuário"
                setUsername("");
                setEmail("");
                setPassword("");
                setRoles([]);
            }
        };

        fetchUserDetails();
    }, [selectedUser]);

    const validateFields = () => {
        if (!username || !email || (!password && selectedUser === "Novo Usuário") || roles.length === 0) {
            setMessage({ text: "Todos os campos são obrigatórios.", type: "danger" });
            return false;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setMessage({ text: "Por favor, insira um e-mail válido.", type: "danger" });
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateFields()) return;

        try {
            setLoading(true);

            if (selectedUser === "Novo Usuário") {
                const response = await api.cadastrarUsuario({
                    username,
                    email,
                    password,
                    roles,
                    empresa_id: empresaId,
                });
                setMessage({ text: response.data.message, type: "success" });
            } else {
                const response = await  api.editarUsuario({
                    userId: selectedUser,
                    username,
                    email,
                    roles,
                });
                setMessage({ text: response.data.message, type: "success" });
            }
        } catch (error) {
            console.error("Erro ao realizar operação:", error);
            setMessage({
                text: error.response?.data?.message || "Erro ao realizar operação.",
                type: "danger",
            });
        } finally {
            setLoading(false);
        }

        setTimeout(() => setMessage({ text: "", type: "" }), 15000);
    };

    return (
        <Card className="shadow-sm mb-4">
            <Card.Header as="h5">Cadastro de Usuário</Card.Header>
            <Card.Body>
                {message.text && <Alert variant={message.type}>{message.text}</Alert>}
                {loading && <Alert variant="info">Carregando...</Alert>}

                <Form.Group controlId="userSelect" className="mb-3">
                    <Form.Label>Selecione o Usuário</Form.Label>
                    <Form.Control
                        as="select"
                        value={selectedUser}
                        onChange={(e) => setSelectedUser(e.target.value)}
                    >
                        <option value="Novo Usuário">Novo Usuário</option>
                        {usuarios.map((user) => (
                            <option key={user.id} value={user.id}>
                                {user.username}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>

                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="username" className="mb-3">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nome do usuário"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="email" className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Email do usuário"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </Form.Group>

                    {selectedUser === "Novo Usuário" && (
                        <Form.Group controlId="password" className="mb-3">
                            <Form.Label>Senha</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Senha"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </Form.Group>
                    )}

                    <Form.Group controlId="roles" className="mb-3">
                        <Form.Label>Roles</Form.Label>
                        <Form.Control
                            as="select"
                            multiple
                            value={roles}
                            onChange={(e) =>
                                setRoles([...e.target.selectedOptions].map((option) => Number(option.value)))
                            }
                            required
                        >
                            {availableRoles.map((role) => (
                                <option key={role.id} value={role.id}>
                                    {role.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>

                    <Button variant="primary" type="submit" className="w-100" disabled={loading}>
                        {selectedUser === "Novo Usuário" ? "Cadastrar" : "Editar"}
                    </Button>
                </Form>
            </Card.Body>
        </Card>
    );
}

export default CadastroUsuarioPanel;
