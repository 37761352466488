import React, { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col, Card } from 'react-bootstrap';
import api from '../../config/api';

function ClientesAtivosPanel() {
    const [supervisor, setSupervisor] = useState('');
    const [supervisores, setSupervisores] = useState([]);
    const [balanceamentoData, setBalanceamentoData] = useState({
        graph_url: null,
        media: 0,
        desvio_padrao: 0,
        total_clientes: 0
    });

    useEffect(() => {
        api.getSupervisores().then(data => {
            const sortedSupervisores = data.supervisores
                .filter(s => s !== null)
                .sort((a, b) => a.localeCompare(b));
            setSupervisores(sortedSupervisores);
        });
    }, []);

    const fetchBalanceamentoData = useCallback(async () => {
        try {
            const response = await api.get('/indicador/balanceamento_supervisor', { params: { supervisor } });
            setBalanceamentoData(response.data);
        } catch (error) {
            console.error('Erro ao buscar dados de balanceamento de supervisores:', error);
        }
    }, [supervisor]);

    useEffect(() => {
        fetchBalanceamentoData();
    }, [fetchBalanceamentoData]);

    return (
        <Card className="shadow-sm mb-4">
            <Card.Header as="h5">
                Balanceamento de Clientes por {supervisor ? 'Representante' : 'Supervisor'}
            </Card.Header>
            <Card.Body>
                <Row className="mb-3">
                    <Col md={4}>
                        <Form.Group controlId="formSupervisor">
                            <Form.Label>Supervisor</Form.Label>
                            <Form.Control
                                as="select"
                                value={supervisor}
                                onChange={e => setSupervisor(e.target.value)}
                            >
                                <option value="">Todos Supervisores</option>
                                {supervisores.map(s => (
                                    <option key={s} value={s}>{s}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                {balanceamentoData.graph_url ? (
                    <div className="balanceamento-chart mt-4 text-center">
                        <img src={`data:image/png;base64,${balanceamentoData.graph_url}`} alt="Balanceamento de Clientes" className="img-fluid mx-auto d-block" />
                    </div>
                ) : (
                    <p>Selecione um supervisor para ver o gráfico.</p>
                )}
                <p>Média de Clientes: {balanceamentoData.media}</p>
                <p>Desvio Padrão de Clientes: {balanceamentoData.desvio_padrao}</p>
                {/* <p>Total de Clientes: {balanceamentoData.total_clientes}</p> */}
            </Card.Body>
        </Card>
    );
}

export default ClientesAtivosPanel;
