import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Card, Image } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom'; // ✅ useNavigate para redirecionamento
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../../assets/logov.png';
import logoFooter from '../../assets/logogopen.png';
import axios from 'axios';

function Login({ onLogin }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [empresas, setEmpresas] = useState([]);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate(); // ✅ Para redirecionar
    console.log('API_BASE_URL:', process.env.REACT_APP_API_BASE_URL);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setLoading(true);

        try {
            const response = await axios.post(
                `${API_BASE_URL.replace(/\/$/, '')}/api/auth/login`,
                { email, password },
                { headers: { 'Content-Type': 'application/json' } }
            );

            const { data } = response;
            console.log('Login realizado com sucesso:', data);

            // Salvar no localStorage
            localStorage.setItem('token', data.token);
            localStorage.setItem('roles', JSON.stringify(data.roles));
            localStorage.setItem('empresaId', data.empresa_id);
            localStorage.setItem('userId', data.user_id);
            localStorage.setItem('empresaName', data.empresa_name);
            localStorage.setItem('userName', data.username); // ✅ Corrigido para userName

            if (data.empresas) {
                localStorage.setItem('empresas', JSON.stringify(data.empresas));
                setEmpresas(data.empresas);
            }

            // ✅ Verificação se onLogin está definido
            if (typeof onLogin === 'function') {
                onLogin(data.user_id, data.username, data.empresa_name, data.roles, data.empresa_id);
            } else {
                console.warn('⚠️ onLogin não está definido, redirecionando...');
                navigate('/'); // ✅ Redireciona para a dashboard caso onLogin não exista
            }
        } catch (error) {
            setLoading(false);
            console.error('Erro ao tentar fazer login:', error);

            if (error.response) {
                if (error.response.status === 401) {
                    setError('Senha incorreta');
                } else if (error.response.status === 404) {
                    setError('Usuário ou empresa não encontrados');
                } else {
                    setError('Erro ao tentar fazer login');
                }
            } else {
                setError('Erro ao conectar-se ao servidor');
            }
        }
    };

    return (
        <Container fluid className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh' }}>
            <Row>
                <Col>
                    <Card className="shadow p-3 mb-5 bg-white rounded" style={{ width: '350px' }}>
                        <Card.Body>
                            <div className="text-center mb-4">
                                <Image src={logo} alt="Logotipo Cadeado" fluid style={{ width: '150px' }} />
                            </div>

                            <Card.Title className="text-center mb-4">Login</Card.Title>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formEmail" className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Digite seu email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="formPassword" className="mb-3">
                                    <Form.Label>Senha</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Digite sua senha"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </Form.Group>

                                {error && <p className="text-danger">{error}</p>}

                                <Button variant="primary" type="submit" className="w-100" disabled={loading}>
                                    {loading ? 'Carregando...' : 'Login'}
                                </Button>
                            </Form>

                            <div className="text-center mt-3">
                                <Link to="/forgot-password">Esqueci a Senha</Link>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <footer className="text-center mt-4" style={{ position: 'absolute', bottom: '30px', width: '100%' }}>
                <p className="mb-1" style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    Powered by
                    <Image src={logoFooter} alt="Logotipo G-Open" fluid style={{ width: '80px', marginLeft: '8px' }} />
                </p>
            </footer>
        </Container>
    );
}

export default Login;
