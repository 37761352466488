import axios from 'axios';
import { jwtDecode } from 'jwt-decode';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Cria uma instância de axios com a URL base configurada
const api = axios.create({
    baseURL: `${API_BASE_URL}/api`,
});

// Verifica se o token é válido
const isTokenValid = () => {
    const token = localStorage.getItem('token');
    if (!token) return false;

    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Math.floor(Date.now() / 1000);
        return decodedToken.exp > currentTime; // Token válido se não expirou
    } catch (error) {
        console.error('Erro ao decodificar o token:', error);
        return false;
    }
};

// Interceptor para adicionar o token JWT automaticamente
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token && isTokenValid()) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Interceptor para lidar com erros globais
api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            console.error('Token expirado ou inválido. Executando logoff...');
            
            // Limpa o token e realiza logoff
            localStorage.removeItem("token");
            localStorage.removeItem("isAuthenticated");
            window.location.href = "/auth/login"; // Redireciona para login
        }
        return Promise.reject(error);
    }
);

// Função para tratar erros de requisição de forma elegante
const handleApiError = (error) => {
    console.error('Erro na requisição API:', error);
    throw error;
};

// =======================
// Funções de Autenticação
// =======================
api.getRoles = async () => {
    try {
        const response = await api.get('/main/roles');
        return response.data.roles;
    } catch (error) {
        handleApiError(error);
    }
};

// ========================
// Funções de Usuário
// ========================
api.cadastrarUsuario = async (dadosUsuario) => {
    try {
        const response = await api.post('/main/cadastrarUsuario', dadosUsuario);
        return response;
    } catch (error) {
        handleApiError(error);
    }
};

api.editarUsuario = async (dadosUsuario) => {
    try {
        const response = await api.put(`/main/editarUsuario/${dadosUsuario.userId}`, dadosUsuario);
        return response;
    } catch (error) {
        handleApiError(error);
    }
};

// Função para cadastrar permissões de acesso (Acesso_Usuario_Indicador)
api.cadastrarPermissao = async (dadosPermissao) => {
    try {
        const response = await api.post('/main/cadastrarPermissao', dadosPermissao);
        return response;
    } catch (error) {
        handleApiError(error);
    }
};

// Função para atualizar permissões de indicadores
api.updatePermissions = async (data) => {
    try {
        const response = await api.post('/main/updatePermissions', data);
        return response;
    } catch (error) {
        handleApiError(error);
    }
};

api.getUserDetails = async (userId) => {
    try {
        const response = await api.get(`/main/userDetails/${userId}`);
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

api.getClients = async () => {
    try {
        const response = await api.get('/indicador/clients');
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

// Função para buscar representantes por supervisor
api.getRepresentantesPorSupervisor = async (supervisor) => {
    try {
        const response = await api.get(`/indicador/representantes/${supervisor}`);
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

// =========================
// Funções de Indicadores
// =========================
api.getIndicatorsByUser = async (userId) => {
    try {
        const response = await api.get(`/main/users/${userId}/indicators`);
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

api.getAllIndicators = async () => {
    try {
        const response = await api.get('/main/allIndicators');
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

api.getEnabledIndicatorsByUser = async (userId) => {
    try {
        const response = await api.get(`/main/enabledIndicators/${userId}`);
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

// ==============================
// Funções Relacionadas a Vendas
// ==============================
api.getVendedoresTicketMedio = async (filters) => {
    try {
        const response = await api.get('/indicador/vendedores_ticket_medio', { params: filters });
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

api.getVendasPorPeriodo = async (dataInicio, dataFim, supervisor, representante, tipoDado) => {
    try {
        const response = await api.get('/indicador/vendas-por-periodo', {
            params: { dataInicio, dataFim, supervisor, representante, tipoDado },
        });
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};


// ================================
// Funções Relacionadas a Clientes
// ================================
api.getClientesAtivos = async (safra, supervisor) => {
    try {
        const response = await api.get('/indicador/clientes_ativos', {
            params: { safra, supervisor },
        });
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

api.getInadimplencia = async (params) => {
    try {
        const response = await api.get('/indicador/inadimplencia', { params });
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

// ================================
// Funções Relacionadas a Produtos
// ================================
api.getProdutos = async () => {
    try {
        const response = await api.get('/products/produtos');
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

// Função para buscar supervisores
api.getSupervisores = async () => {
    try {
        const response = await api.get('/indicador/supervisores');
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

// Função para buscar representantes
api.getRepresentantes = async () => {
    try {
        const response = await api.get('/indicador/representantes');
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

// Função para buscar safras
api.getSafras = async () => {
    try {
        const response = await api.get('/indicador/safras');
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

// Função para buscar vendas por representante
api.getVendasPorRepresentante = async (dataInicio, dataFim, supervisor, representante) => {
    try {
        const response = await api.get('/indicador/vendas-por-representante', {
            params: {
                dataInicio,
                dataFim,
                supervisor,
                representante,
            },
        });
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

// Função para buscar vendas por todos os representantes
api.getVendasPorTodosRepresentantes = async (dataInicio, dataFim, supervisor) => {
    try {
        const response = await api.get('/indicador/vendas-por-todos-representantes', {
            params: {
                dataInicio,
                dataFim,
                supervisor,
            },
        });
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

api.getMargemLucro = async (dataInicio, dataFim, supervisor) => {
    try {
        const response = await api.get('/indicador/margem_lucro', {
            params: {
                dataInicio,
                dataFim,
                supervisor,
            },
        });
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

api.getVendasPorSupervisorProduto = async (dataInicio, dataFim, supervisor) => {
    try {
        const response = await api.get('/indicador/vendas-por-supervisor-produto', {
            params: {
                dataInicio,
                dataFim,
                supervisor,
            },
        });
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

api.getVendasPorRepresentanteProduto = async (dataInicio, dataFim, supervisor, representante) => {
    try {
        const response = await api.get('/indicador/vendas-por-representante-produto', {
            params: { 
                dataInicio, 
                dataFim, 
                supervisor, 
                representante 
            },
        });
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

// =========================
// Funções de Setores
// =========================
api.getSetores = async (empresaId) => {
    try {
        const response = await api.get('/gbo/setor', { params: { empresa_id: empresaId } });
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

api.getSetorById = async (setorId) => {
    try {
        const response = await api.get(`/gbo/setor/${setorId}`);
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

api.createSetor = async (dadosSetor) => {
    try {
        return await api.post('/gbo/setor', dadosSetor);
    } catch (error) {
        handleApiError(error);
    }
};

api.updateSetor = async (setorId, dadosSetor) => {
    try {
        return await api.put(`/gbo/setor/${setorId}`, dadosSetor);
    } catch (error) {
        handleApiError(error);
    }
};

api.deleteSetor = async (setorId) => {
    try {
        return await api.delete(`/gbo/setor/${setorId}`);
    } catch (error) {
        handleApiError(error);
    }
};

// ================================
// Exportação de API
// ================================
export default api;
