import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import api from '../../config/api';

function Inadimplencia() {
    const [dataInicio, setDataInicio] = useState('');
    const [vencimento, setVencimento] = useState('');
    const [formasPgto, setFormasPgto] = useState([]);
    const [supervisor, setSupervisor] = useState('');
    const [supervisores, setSupervisores] = useState([]);
    const [graphUrl, setGraphUrl] = useState(null);
    const [totalInadimplente, setTotalInadimplente] = useState(0);
    const [percentualInadimplencia, setPercentualInadimplencia] = useState("");

    // Carregar lista de supervisores
    useEffect(() => {
        // Carregar supervisores e ordená-los em ordem alfabética
        api.getSupervisores()
            .then(data => {
                const sortedSupervisores = data.supervisores
                    .filter(s => s) // Filtrar valores nulos ou vazios
                    .sort((a, b) => a.localeCompare(b)); // Ordenar alfabeticamente
                setSupervisores(sortedSupervisores);
            })
            .catch(err => console.error('Erro ao carregar supervisores:', err));
    }, []);
    
    // Função para manipular a seleção de forma de pagamento
    const handleFormaPgtoChange = (e) => {
        const { value, checked } = e.target;

        if (value === 'TODOS') {
            // Se "Todos" for selecionado, desmarque todas as outras opções
            setFormasPgto(checked ? ['TODOS'] : []);
        } else {
            // Se qualquer outra opção for selecionada, remova "Todos" das opções
            let updatedFormasPgto = formasPgto.includes('TODOS')
                ? formasPgto.filter((item) => item !== 'TODOS')
                : [...formasPgto];

            if (checked) {
                updatedFormasPgto.push(value);
            } else {
                updatedFormasPgto = updatedFormasPgto.filter((item) => item !== value);
            }

            setFormasPgto(updatedFormasPgto);
        }
    };

    const handleSearch = () => {
        const params = {
            dataInicio,
            vencimento,
            formaPgto: formasPgto.length ? formasPgto : undefined, // Só envia se houver algum item selecionado
            supervisor
        };
        
        api.getInadimplencia(params)
        .then(response => {
            setTotalInadimplente(response.total_inadimplente);
            setPercentualInadimplencia(response.percentual_inadimplencia); // Atualiza o percentual no estado
            setGraphUrl(response.graph_url);
        })
        .catch(error => console.error("Erro ao buscar inadimplência:", error));    
    };

    return (
        <Card className="shadow-sm mb-4">
            <Card.Header as="h5">
                Inadimplência
            </Card.Header>
            <Card.Body>
                <Row className="mb-3">
                    <Col md={4}>
                        <Form.Group controlId="formDataInicio">
                            <Form.Label>Data Início</Form.Label>
                            <Form.Control type="date" value={dataInicio} onChange={e => setDataInicio(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="formVencimento">
                            <Form.Label>Data Fim</Form.Label>
                            <Form.Control type="date" value={vencimento} onChange={e => setVencimento(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={4}>
                        <Form.Group controlId="formSupervisor">
                            <Form.Label>Supervisor</Form.Label>
                            <Form.Control as="select" value={supervisor} onChange={e => setSupervisor(e.target.value)}>
                                <option value="">Selecione o Supervisor</option>
                                {supervisores.map(s => <option key={s} value={s}>{s}</option>)}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="formFormaPgto">
                            <Form.Label>Forma de Pagamento</Form.Label>
                            <Form.Check
                                type="checkbox"
                                label="Todos"
                                value="TODOS"
                                onChange={handleFormaPgtoChange}
                                checked={formasPgto.includes('TODOS')}
                            />
                            <Form.Check
                                type="checkbox"
                                label="Boleto"
                                value="BOLETO"
                                onChange={handleFormaPgtoChange}
                                checked={formasPgto.includes('BOLETO')}
                            />
                            <Form.Check
                                type="checkbox"
                                label="Crediário"
                                value="CREDIARIO"
                                onChange={handleFormaPgtoChange}
                                checked={formasPgto.includes('CREDIARIO')}
                            />                            
                            <Form.Check
                                type="checkbox"
                                label="Cheque"
                                value="CHEQUE"
                                onChange={handleFormaPgtoChange}
                                checked={formasPgto.includes('CHEQUE')}
                            />
                            {/* Adicione outros métodos de pagamento conforme necessário */}
                        </Form.Group>
                    </Col>
                </Row>

                <Button variant="primary" onClick={handleSearch}>Buscar Inadimplência</Button>

                {graphUrl && (
                    <div className="mt-4 text-center">
                        <h5>Total Inadimplente: {totalInadimplente} ({percentualInadimplencia})</h5>
                        <img src={`data:image/png;base64,${graphUrl}`} alt="Gráfico de Inadimplência" className="img-fluid mx-auto d-block" />
                    </div>
                )}
            </Card.Body>
        </Card>
    );
}

export default Inadimplencia;
