import React, { useState, useEffect } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import api from '../../config/api';

function Setor() {
  const [setores, setSetores] = useState([]);
  const [selectedSetor, setSelectedSetor] = useState("Novo Setor");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [message, setMessage] = useState({ text: "", type: "" });
  const [loading, setLoading] = useState(false);
  const [empresaId, setEmpresaId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const empresaIdFromStorage = localStorage.getItem("empresaId");

        if (!empresaIdFromStorage) {
          throw new Error("Empresa não encontrada no localStorage.");
        }

        setEmpresaId(empresaIdFromStorage);
        const response = await api.get("/gbo/setor", { params: { empresa_id: empresaIdFromStorage } });
        setSetores(response.data);
      } catch (error) {
        console.error("Erro ao carregar setores:", error);
        setMessage({ text: error.response?.data?.message || "Erro ao carregar setores.", type: "danger" });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !description) {
      setMessage({ text: "Todos os campos são obrigatórios.", type: "danger" });
      return;
    }

    try {
      setLoading(true);

      if (selectedSetor === "Novo Setor") {
        await api.post("/gbo/setor", { name, description, empresa_id: empresaId });
        setMessage({ text: "Setor cadastrado com sucesso!", type: "success" });
      } else {
        await api.put(`/gbo/setor/${selectedSetor}`, { name, description });
        setMessage({ text: "Setor atualizado com sucesso!", type: "success" });
      }

      const response = await api.get("/gbo/setor", { params: { empresa_id: empresaId } });
      setSetores(response.data);
    } catch (error) {
      console.error("Erro ao realizar operação:", error);
      setMessage({ text: error.response?.data?.message || "Erro ao realizar operação.", type: "danger" });
    } finally {
      setLoading(false);
    }
  };

  const handleSetorChange = async (e) => {
    const setorId = e.target.value;
    setSelectedSetor(setorId);

    if (setorId !== "Novo Setor") {
      try {
        const response = await api.get(`/gbo/setor/${setorId}`);
        setName(response.data.name);
        setDescription(response.data.description);
      } catch (error) {
        console.error("Erro ao buscar detalhes do setor:", error);
        setMessage({ text: "Erro ao buscar detalhes do setor.", type: "danger" });
      }
    } else {
      setName("");
      setDescription("");
    }
  };

  return (
    <Card className="shadow-sm mb-4">
      <Card.Header as="h5">Cadastro de Setor</Card.Header>
      <Card.Body>
        {message.text && <Alert variant={message.type}>{message.text}</Alert>}
        {loading && <Alert variant="info">Carregando...</Alert>}

        <Form.Group controlId="setorSelect" className="mb-3">
          <Form.Label>Selecione o Setor</Form.Label>
          <Form.Control as="select" value={selectedSetor} onChange={handleSetorChange}>
            <option value="Novo Setor">Novo Setor</option>
            {setores.map((setor) => (
              <option key={setor.id} value={setor.id}>{setor.name}</option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="name" className="mb-3">
            <Form.Label>Nome</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nome do setor"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="description" className="mb-3">
            <Form.Label>Descrição</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Descrição do setor"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </Form.Group>

          <Button variant="primary" type="submit" className="w-100" disabled={loading}>
            {selectedSetor === "Novo Setor" ? "Cadastrar" : "Atualizar"}
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
}

export default Setor;
