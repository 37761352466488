import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

// Importação dos componentes principais da aplicação
import Dashboard from "./screens/dashboard/Dashboard";
import Login from "./screens/login/Login";
import Permissions from "./config/permissions/permissions";
import CadastrarUsuario from "./screens/register/CadastroUsuarioPanel";
import PermissaoUsuario from "./screens/permission/PermissaoUsuarioPanel";
import AlterarSenhaPanel from "./screens/register/AlterarSenhaPanel";
import ForgotPassword from './screens/login/EsqueciSenhaPanel';
import ResetPassword from "./screens/login/ResetSenhaPanel";
import VerifyCode from "./screens/login/VerificaCodigoPanel";
import SelecionaEmpresa from "./screens/login/SelecionaEmpresa";
import Setor from "./screens/register/Setor";

function App() {
    // Estados para autenticação e informações do usuário
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [empresaName, setEmpresaName] = useState('');
    const [userId, setUserId] = useState(null);
    const [userType, setUserType] = useState('');
    const [userName, setUserName] = useState('');
    const [loading, setLoading] = useState(true);

    // Validação do token JWT ao carregar a aplicação
    useEffect(() => {
        const validateToken = () => {
            const token = localStorage.getItem('token');
            if (!token) {
                handleLogoff(false);
                setLoading(false);
                return;
            }
            try {
                const decoded = jwtDecode(token);
                const currentTime = Date.now() / 1000;
                if (decoded.exp < currentTime) {
                    handleLogoff(false);
                } else {
                    setIsAuthenticated(true);
                    setEmpresaName(localStorage.getItem('empresaName') || '');
                    setUserId(parseInt(localStorage.getItem('userId'), 10) || null);
                    setUserType(localStorage.getItem('userType') || '');
                    setUserName(localStorage.getItem('userName') || '');
                }
            } catch (error) {
                handleLogoff(false);
            }
            setLoading(false);
        };

        validateToken();
    }, []);

    // Função para tratar o login e armazenar informações no localStorage
    const handleLogin = (userId, userName, empresaName, roles, empresaId) => {
        setIsAuthenticated(true);
        setEmpresaName(empresaName);
        setUserId(userId);
        setUserType(roles);
        setUserName(userName);

        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('empresaName', empresaName);
        localStorage.setItem('userId', userId);
        localStorage.setItem('userType', roles);
        localStorage.setItem('userName', userName);
        localStorage.setItem('empresaId', empresaId);
    };

    // Função para tratar o logoff e limpar o localStorage
    const handleLogoff = (redirect = true) => {
        setIsAuthenticated(false);
        setEmpresaName('');
        setUserId(null);
        setUserName('');

        localStorage.clear();
        if (redirect) window.location.href = '/login';
    };

    // Componente para proteger rotas baseadas em autenticação e permissões
    const ProtectedRoute = ({ element, allowedRoles }) => {
        const token = localStorage.getItem('token');
        if (!token) return <Navigate to="/login" />;

        try {
            const decoded = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            if (decoded.exp < currentTime) {
                handleLogoff();
                return <Navigate to="/login" />;
            }
            if (allowedRoles && !allowedRoles.includes(localStorage.getItem('userType'))) {
                return <Navigate to="/" />;
            }
            return element;
        } catch (error) {
            handleLogoff();
            return <Navigate to="/login" />;
        }
    };

    // Função para atualizar o estado e localStorage após a seleção de uma empresa
    const handleEmpresaSelecionada = (empresaId, empresaName) => {
        localStorage.setItem("empresaId", empresaId);
        localStorage.setItem("empresaName", empresaName);
        setEmpresaName(empresaName);
    };

    if (loading) {
        return <div style={{ textAlign: 'center', marginTop: '20%' }}>Carregando...</div>;
    }

    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={isAuthenticated ? <Dashboard empresaName={empresaName} userId={userId} userType={userType} userName={userName} onLogoff={handleLogoff} /> : <Navigate to="/login" />} />
                    <Route path="/login" element={isAuthenticated ? <Navigate to="/" /> : <Login onLogin={handleLogin} />} />
                    <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard empresaName={empresaName} userId={userId} userType={userType} userName={userName} onLogoff={handleLogoff} />} />} />
                    <Route path="/cadastrousuario" element={<ProtectedRoute element={<CadastrarUsuario />} allowedRoles={Permissions.AG.concat(Permissions.AS)} />} />
                    <Route path="/permissaousuario" element={<ProtectedRoute element={<PermissaoUsuario />} allowedRoles={Permissions.AG.concat(Permissions.AS)} />} />
                    <Route path="/alterarsenha" element={<ProtectedRoute element={<AlterarSenhaPanel />} />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/verify-code" element={<VerifyCode />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/trocar-empresa" element={<ProtectedRoute element={<SelecionaEmpresa onEmpresaSelecionada={handleEmpresaSelecionada} />} allowedRoles={['Admin Geral']} />} />
                    <Route path="/setor" element={<ProtectedRoute element={<Setor/>} allowedRoles={Permissions.AG.concat(Permissions.AS)} />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
