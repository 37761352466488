import React, { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col, Card, Button } from 'react-bootstrap';
import api from '../../config/api';
import debounce from 'lodash.debounce';  // Instale com "npm install lodash.debounce"

function ClientesAtivosPanel() {
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [supervisor, setSupervisor] = useState('');
    const [supervisores, setSupervisores] = useState([]);
    const [clientesData, setClientesData] = useState({
        graph_url: null,
        percentual_clientes_ativos: '0%',
        total_clientes: 0,
        clientes_compraram: 0,
        clientes_novos: 0,
        clientes_ativos_anteriores: 0
    });

    useEffect(() => {
        api.getSupervisores().then(data => {
            const sortedSupervisores = data.supervisores
                .filter(s => s !== null)
                .sort((a, b) => a.localeCompare(b));
            setSupervisores(sortedSupervisores);
        });
    }, []);

    // Função para buscar dados apenas quando o usuário clicar no botão
    const fetchClientesData = useCallback(debounce(async () => {
        if (dataInicio && dataFim) {
            try {
                console.log("Buscando dados com:", { dataInicio, dataFim, supervisor });
                const response = await api.get('/indicador/clientes_ativos', { params: { dataInicio, dataFim, supervisor } });
                console.log("Response data:", response.data);
                setClientesData(response.data);
            } catch (error) {
                console.error('Erro ao buscar dados de clientes ativos:', error);
            }
        }
    }, 500), [dataInicio, dataFim, supervisor]); // Debounce com 500ms para evitar requisições em excesso

    return (
        <Card className="shadow-sm mb-4">
            <Card.Header as="h5">Clientes Ativos</Card.Header>
            <Card.Body>
                <Row className="mb-3">
                    <Col md={4}>
                        <Form.Group controlId="formDataInicio">
                            <Form.Label>Data Início</Form.Label>
                            <Form.Control
                                type="date"
                                value={dataInicio}
                                onChange={e => setDataInicio(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="formDataFim">
                            <Form.Label>Data Fim</Form.Label>
                            <Form.Control
                                type="date"
                                value={dataFim}
                                onChange={e => setDataFim(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="formSupervisor">
                            <Form.Label>Supervisor</Form.Label>
                            <Form.Control
                                as="select"
                                value={supervisor}
                                onChange={e => setSupervisor(e.target.value)}
                            >
                                <option value="">Selecione o Supervisor</option>
                                {supervisores.map(s => (
                                    <option key={s} value={s}>{s}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                {/* Botão para acionar a busca */}
                <Row className="mb-3">
                    <Col md={12} className="text-center">
                        <Button variant="primary" onClick={fetchClientesData}>
                            Buscar Dados
                        </Button>
                    </Col>
                </Row>

                {clientesData.graph_url ? (
                    <div className="clientes-ativos-chart mt-4 text-center">
                        <h5>Percentual de Clientes Ativos: {clientesData.percentual_clientes_ativos}</h5>
                        <img src={`data:image/png;base64,${clientesData.graph_url}`} alt="Gráfico de Clientes" className="img-fluid mx-auto d-block" />
                        <p>Total de Clientes: {clientesData.total_clientes}</p>
                        <p>Clientes que Compraram: {clientesData.clientes_compraram}</p>
                        <p>Clientes Novos: {clientesData.clientes_novos}</p>
                        <p>Clientes Ativos (Já Compraram): {clientesData.clientes_ativos_anteriores}</p>
                    </div>
                ) : (
                    <p>Selecione uma data de início, data de fim e um supervisor para ver os gráficos.</p>
                )}
            </Card.Body>
        </Card>
    );
}

export default ClientesAtivosPanel;
