import React, { useState } from "react";
import { FaCogs, FaSignOutAlt, FaKey, FaChartBar, FaUsers, FaClipboardList, FaBuilding } from "react-icons/fa";
import { Collapse } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Sidebar.css";

const RolePermissions = {
  "Admin Geral": ["Permissoes", "CadastroUsuario", "GBO", "TrocarEmpresa"],
  "Admin Empresa": ["CadastroUsuario", "GBO"],
  Supervisor: [],
};

function Sidebar({ indicators = [], onSelectIndicator, onSelectScreen, onLogoff, onChangePassword }) {
  const [openCadastros, setOpenCadastros] = useState(false);
  const [openIndicadores, setOpenIndicadores] = useState(false);
  const [openConfiguracoes, setOpenConfiguracoes] = useState(false);
  const [openGBO, setOpenGBO] = useState(false);
  const navigate = useNavigate();

  // Recuperar roles do localStorage
  const roles = JSON.parse(localStorage.getItem("roles")) || [];
  const userPermissions = roles.flatMap((role) => RolePermissions[role] || []);

  return (
    <div className="sidebar">
      {/* Header do Sidebar */}
      <div className="sidebar-header">
        <FaChartBar size={24} className="sidebar-icon" />
        <span className="sidebar-title">Dashboard</span>
      </div>

      {/* Menu */}
      <ul className="sidebar-menu">
        {/* CADASTROS */}
        {userPermissions.length > 0 && (
          <>
            <li
              className={`sidebar-dropdown ${openCadastros ? "active" : ""}`}
              onClick={() => setOpenCadastros(!openCadastros)}
            >
              <FaUsers className="sidebar-icon" />
              <span>Cadastros</span>
            </li>
            <Collapse in={openCadastros}>
              <ul className="sidebar-submenu">
                {userPermissions.includes("Permissoes") && (
                  <li onClick={() => onSelectScreen("permissaousuariopanel")}>
                    <FaKey className="sidebar-icon" />
                    <span>Permissões</span>
                  </li>
                )}
                {userPermissions.includes("CadastroUsuario") && (
                  <li onClick={() => onSelectScreen("cadastrousuariopanel")}>
                    <FaUsers className="sidebar-icon" />
                    <span>Usuários</span>
                  </li>
                )}
                {/* Submenu GBO */}
                {userPermissions.includes("GBO") && (
                  <>
                    <li
                      className={`sidebar-dropdown ${openGBO ? "active" : ""}`}
                      onClick={() => setOpenGBO(!openGBO)}
                    >
                      <FaClipboardList className="sidebar-icon" />
                      <span>GBO</span>
                    </li>
                    <Collapse in={openGBO}>
                      <ul className="sidebar-submenu">
                        <li onClick={() => onSelectScreen("gboSetor")}>
                          <FaClipboardList className="sidebar-icon" />
                          <span>Setor</span>
                        </li>
                      </ul>
                    </Collapse>
                  </>
                )}
              </ul>
            </Collapse>
          </>
        )}

        {/* INDICADORES */}
        <li
          className={`sidebar-dropdown ${openIndicadores ? "active" : ""}`}
          onClick={() => setOpenIndicadores(!openIndicadores)}
        >
          <FaChartBar className="sidebar-icon" />
          <span>Indicadores</span>
        </li>
        <Collapse in={openIndicadores}>
          <ul className="sidebar-submenu">
            {indicators.length > 0 ? (
              indicators.map((indicator) => (
                <li key={indicator.id} onClick={() => onSelectIndicator(indicator.name)}>
                  <FaChartBar className="sidebar-icon" />
                  <span>{indicator.descricao}</span>
                </li>
              ))
            ) : (
              <li>
                <FaChartBar className="sidebar-icon" />
                <span>Nenhum indicador disponível</span>
              </li>
            )}
          </ul>
        </Collapse>

        {/* CONFIGURAÇÕES */}
        <li
          className={`sidebar-dropdown ${openConfiguracoes ? "active" : ""}`}
          onClick={() => setOpenConfiguracoes(!openConfiguracoes)}
        >
          <FaCogs className="sidebar-icon" />
          <span>Configurações</span>
        </li>
        <Collapse in={openConfiguracoes}>
          <ul className="sidebar-submenu">
            <li onClick={onChangePassword}>
              <FaKey className="sidebar-icon" />
              <span>Alterar Senha</span>
            </li>
            {/* Apenas Admin Geral pode trocar empresa */}
            {userPermissions.includes("TrocarEmpresa") && (
              <li onClick={() => navigate("/trocar-empresa")}>
                <FaBuilding className="sidebar-icon" />
                <span>Trocar Empresa</span>
              </li>
            )}
            <li onClick={onLogoff}>
              <FaSignOutAlt className="sidebar-icon" />
              <span>Logoff</span>
            </li>
          </ul>
        </Collapse>
      </ul>
    </div>
  );
}

export default Sidebar;
