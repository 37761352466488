import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import api from '../../config/api';

function TicketMedio() {
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [supervisor, setSupervisor] = useState('');
    const [representante, setRepresentante] = useState('');
    const [supervisores, setSupervisores] = useState([]);
    const [representantes, setRepresentantes] = useState([]);
    const [totalClientes, setTotalClientes] = useState(0);
    const [totalVendas, setTotalVendas] = useState(0);
    const [ticketMedioTotal, setTicketMedioTotal] = useState(0);
    const [graphData, setGraphData] = useState('');
    const [selectedMode, setSelectedMode] = useState('mes'); // 'mes' ou 'supervisor'
    const [selectedGraph, setSelectedGraph] = useState('reais'); // 'reais' ou 'kgs'

    useEffect(() => {
        // Carregar supervisores
        api.getSupervisores()
            .then(data => {
                const sortedSupervisores = data.supervisores
                    .filter(s => s)
                    .sort((a, b) => a.localeCompare(b));
                setSupervisores(sortedSupervisores);
            })
            .catch(err => console.error('Erro ao carregar supervisores:', err));
    }, []);

    useEffect(() => {
        if (supervisor) {
            // Carregar representantes do supervisor
            api.getRepresentantesPorSupervisor(supervisor)
                .then(data => {
                    const sortedRepresentantes = data.representantes
                        .filter(r => r)
                        .sort((a, b) => a.localeCompare(b));
                    setRepresentantes(sortedRepresentantes);
                })
                .catch(err => console.error('Erro ao carregar representantes:', err));
        } else {
            setRepresentantes([]);
        }
    }, [supervisor]);

    useEffect(() => {
        // Limpar supervisor e representante ao alternar para o modo "Mês a Mês"
        if (selectedMode === 'mes') {
            setSupervisor('');
            setRepresentante('');
        }
    }, [selectedMode]);

    const handleSearch = async () => {
        if (!dataInicio || !dataFim) {
            alert('Por favor, selecione uma data de início e fim.');
            return;
        }
    
        try {
            const endpoint =
                selectedMode === 'mes'
                    ? selectedGraph === 'reais'
                        ? '/indicador/ticket_medio_mes_reais'
                        : '/indicador/ticket_medio_mes_kgs'
                    : selectedGraph === 'reais'
                        ? '/indicador/ticket_medio_supervisor_reais'
                        : '/indicador/ticket_medio_supervisor_kgs';
    
            const params =
                selectedMode === 'mes'
                    ? { dataInicio, dataFim }
                    : { dataInicio, dataFim, supervisor, representante };
    
            const response = await api.get(endpoint, { params });
    
            setGraphData(response.data.graph_url || ''); // Atualiza gráfico
            setTotalClientes(response.data.total_clientes || 0); // Total de clientes
            setTotalVendas(response.data.total_vendas || 0); // Total de vendas
            setTicketMedioTotal(response.data.total_clientes > 0 ? response.data.total_vendas / response.data.total_clientes : 0); // Calcula ticket médio total
        } catch (error) {
            console.error('Erro ao buscar dados do Ticket Médio:', error);
            alert('Erro ao buscar dados. Verifique o console para mais detalhes.');
        }
    };
    
    return (
        <Card className="shadow-sm mb-4">
            <Card.Header as="h5">
                Ticket Médio
            </Card.Header>
            <Card.Body>
                <Row className="mb-3">
                    <Col md={4}>
                        <Form.Group controlId="formDataInicio">
                            <Form.Label>Data Início</Form.Label>
                            <Form.Control
                                type="date"
                                value={dataInicio}
                                onChange={e => setDataInicio(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="formDataFim">
                            <Form.Label>Data Fim</Form.Label>
                            <Form.Control
                                type="date"
                                value={dataFim}
                                onChange={e => setDataFim(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    {selectedMode === 'supervisor' && (
                        <Col md={4}>
                            <Form.Group controlId="formSupervisor">
                                <Form.Label>Supervisor</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={supervisor}
                                    onChange={e => setSupervisor(e.target.value)}
                                >
                                    <option value="">Selecione o Supervisor</option>
                                    {supervisores.map(s => (
                                        <option key={s} value={s}>{s}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    )}
                </Row>
                {selectedMode === 'supervisor' && supervisor && (
                    <Row className="mb-3">
                        <Col md={4}>
                            <Form.Group controlId="formRepresentante">
                                <Form.Label>Representante</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={representante}
                                    onChange={e => setRepresentante(e.target.value)}
                                >
                                    <option value="">Selecione o Representante</option>
                                    {representantes.map(r => (
                                        <option key={r} value={r}>{r}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                )}
                <Row className="mb-3">
                    <Col>
                        <Form.Check
                            type="radio"
                            id="radioMes"
                            name="viewMode"
                            label="Mês a Mês"
                            value="mes"
                            checked={selectedMode === 'mes'}
                            onChange={() => setSelectedMode('mes')}
                        />
                    </Col>
                    <Col>
                        <Form.Check
                            type="radio"
                            id="radioSupervisor"
                            name="viewMode"
                            label="Por Supervisor"
                            value="supervisor"
                            checked={selectedMode === 'supervisor'}
                            onChange={() => setSelectedMode('supervisor')}
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Check
                            type="radio"
                            id="radioReais"
                            name="graphType"
                            label="Ticket Médio (R$)"
                            value="reais"
                            checked={selectedGraph === 'reais'}
                            onChange={() => setSelectedGraph('reais')}
                        />
                    </Col>
                    <Col>
                        <Form.Check
                            type="radio"
                            id="radioKgs"
                            name="graphType"
                            label="Ticket Médio (Kg)"
                            value="kgs"
                            checked={selectedGraph === 'kgs'}
                            onChange={() => setSelectedGraph('kgs')}
                        />
                    </Col>
                </Row>
                <Button variant="primary" onClick={handleSearch}>
                    Buscar Ticket Médio
                </Button>
                <div className="mt-4 text-center">
                    <h6>Total de Clientes: {totalClientes}</h6>
                    <h6>
                        {selectedGraph === 'reais'
                            ? `Ticket Médio Total (R$): R$${ticketMedioTotal.toFixed(2)}`
                            : `Ticket Médio Total (Kg): ${ticketMedioTotal.toFixed(2)} Kg`}
                    </h6>
                    {graphData ? (
                        <img
                            src={`data:image/png;base64,${graphData}`}
                            alt="Gráfico de Ticket Médio"
                            className="img-fluid mx-auto d-block"
                        />
                    ) : (
                        <p>Não há gráficos disponíveis. Selecione um período e clique em "Buscar".</p>
                    )}
                </div>


            </Card.Body>
        </Card>
    );
}

export default TicketMedio;
