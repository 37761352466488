import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../config/api";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import Sidebar from "./Sidebar";
import AlterarSenhaPanel from "../register/AlterarSenhaPanel";
import CadastroUsuarioPanel from "../register/CadastroUsuarioPanel";
import PermissaoUsuarioPanel from "../permission/PermissaoUsuarioPanel";
import SelecionaEmpresa from "../login/SelecionaEmpresa";
import Setor from "../register/Setor";
import logo from "../../assets/logov.png";

// Indicadores
import {
  ProspeccaoPanel,
  ClientesInativosPanel,
  VendedoresTicketMedioPanel,
  BalanceamentoRepres_ClienteAtivo,
  CausaReclamacoes,
  ClienteAtivo,
  Inadimplencia,
  IndiceTratativaQuantitativo,
  IndiceVendaAbaixoTabela,
  Inventario,
  MapaCalor,
  MargemLucroPanel,
  MunicipioInativo,
  PosVendaQualitativo,
  PosVendaQuantitativo,
  ReversaoTratativas,
  TempoReclamacaoVisita,
  TicketMedio,
  TipoTratativa,
  VendaProdutoSupervisor,
  VendaSupervisor,
  VisitaTecnicaQualitativo,
  VisitaTecnicaQuantitativo,
} from "../../components/indicators";

// Mapeamento de indicadores
const indicatorComponents = {
  balancrepres_ticketmedio: VendedoresTicketMedioPanel,
  clientesinativos: ClientesInativosPanel,
  prospeccao: ProspeccaoPanel,
  balanrepres_clienteativo: BalanceamentoRepres_ClienteAtivo,
  causareclamacoes: CausaReclamacoes,
  clientesativos: ClienteAtivo,
  inadimplencia: Inadimplencia,
  indicetratativaquant: IndiceTratativaQuantitativo,
  indicevendaabaixotabela: IndiceVendaAbaixoTabela,
  inventario: Inventario,
  mapacalor: MapaCalor,
  margemlucro: MargemLucroPanel,
  municipioinativo: MunicipioInativo,
  posvendaqualitativo: PosVendaQualitativo,
  posvendaquantitativo: PosVendaQuantitativo,
  reversaotratativas: ReversaoTratativas,
  temporeclamacaovisita: TempoReclamacaoVisita,
  ticketmedio: TicketMedio,
  tipotratativa: TipoTratativa,
  vendaprodutosupervisor: VendaProdutoSupervisor,
  vendasupervisor: VendaSupervisor,
  visitatecnicaqualit: VisitaTecnicaQualitativo,
  visitatecnicaquant: VisitaTecnicaQuantitativo,
};

function Dashboard({ empresaName, userId, userType, onLogoff, userName }) {
  const [indicators, setIndicators] = useState([]);
  const [selectedIndicator, setSelectedIndicator] = useState(null);
  const [selectedScreen, setSelectedScreen] = useState(null);
  const [empresas, setEmpresas] = useState([]);
  const [selectedEmpresa, setSelectedEmpresa] = useState(
    localStorage.getItem("empresaId") || ""
  );
  const [isChoosingEmpresa, setIsChoosingEmpresa] = useState(false);
  const [empresaDisplayName, setEmpresaDisplayName] = useState(empresaName);
  const [empresaLogo, setEmpresaLogo] = useState(
    localStorage.getItem("empresaLogo") || ""
  );

  const location = useLocation();
  const navigate = useNavigate();

  // Resetar seleção ao mudar de rota
  useEffect(() => {
    setSelectedIndicator(null);
    setSelectedScreen(null);
  }, [location.pathname]);

  // Carregar empresas ou indicadores
  useEffect(() => {
    if (userType.includes("Admin Geral")) {
      setIsChoosingEmpresa(true);
      api
        .get("/main/empresas")
        .then((response) => {
          setEmpresas(response.data);
        })
        .catch((error) => {
          console.error("Erro ao carregar empresas:", error);
        });
    } else {
      loadIndicators();
    }
  }, [userId, userType]);

  useEffect(() => {
    const storedEmpresaId = localStorage.getItem("empresaId");
  
    if (!storedEmpresaId || storedEmpresaId === "null") {
      console.warn("⚠️ Nenhuma empresa encontrada no localStorage. Redirecionando para seleção...");
      setIsChoosingEmpresa(true); // Habilita a tela de seleção
    } else {
      console.log("🔄 Empresa carregada do localStorage:", storedEmpresaId);
      setSelectedEmpresa(storedEmpresaId);
      setEmpresaDisplayName(localStorage.getItem("empresaName"));
      setEmpresaLogo(localStorage.getItem("empresaLogo"));
      setIsChoosingEmpresa(false);
      loadIndicators();
    }
  }, []);
  
  
  const loadIndicators = () => {
    const empresaId = localStorage.getItem("empresaId");
    console.log("Carregando indicadores para Empresa ID:", empresaId, "e User ID:", userId);

    if (userId && empresaId) {
      api
        .get(`/main/users/${userId}/indicators`, {
          params: { empresaId },
        })
        .then((response) => {
          console.log("Indicadores recebidos:", response.data.indicators);
          setIndicators(response.data.indicators || []); // Garante que será um array
        })
        .catch((error) => {
          console.error("Erro ao carregar indicadores:", error);
        });
    } else {
      console.error("Usuário ou Empresa não definidos.");
    }
  };

  useEffect(() => {
    loadIndicators();
  }, [selectedEmpresa]);

  const handleEmpresaSelection = (empresaId) => {
    const empresa = empresas.find((e) => e.id === parseInt(empresaId));
    if (empresa) {
        console.log("Empresa selecionada:", empresa);

        // Construção da URL correta
        const logoUrl = empresa.logo_url.startsWith("http")
          ? empresa.logo_url
          : `https://matrix.g-open.com.br/logos/${empresa.logo_url}`;

        // Atualiza LocalStorage
        localStorage.setItem("empresaId", empresa.id);
        localStorage.setItem("empresaName", empresa.name);
        localStorage.setItem("empresaLogo", logoUrl);

        // Atualiza estados
        setEmpresaDisplayName(empresa.name);
        setEmpresaLogo(logoUrl);
        setSelectedEmpresa(empresa.id);
        setIsChoosingEmpresa(false);

        // ⚡ Aguarde o estado ser atualizado antes de carregar os indicadores
        setTimeout(() => {
            console.log("Atualizando indicadores após seleção da empresa.");
            loadIndicators();
            navigate("/");
        }, 100);
    }
  };

  const renderSelectedIndicator = () => {
    if (!selectedIndicator) return null;
    const SelectedComponent = indicatorComponents[selectedIndicator];
    return SelectedComponent ? <SelectedComponent /> : <p>Indicador não encontrado.</p>;
  };

  const renderSelectedScreen = () => {
    if (!selectedScreen) return null;
    switch (selectedScreen) {
      case "cadastrousuariopanel":
        return <CadastroUsuarioPanel />;
      case "permissaousuariopanel":
        return <PermissaoUsuarioPanel />;
      case "alterarsenhapanel":
        return <AlterarSenhaPanel userId={userId} />;     
      case "gboSetor":
        return <Setor />;
    }
  };

  if (isChoosingEmpresa) {
    return (
      <SelecionaEmpresa
        empresas={empresas}
        onEmpresaSelecionada={handleEmpresaSelection}
      />
    );
  }

  return (
    <Container fluid className="h-100 p-0">
      <Row className="gx-0 h-100">
        <Col md={3} className="p-0 h-100">
          <Sidebar
            indicators={indicators}
            onSelectIndicator={(indicator) => {
              setSelectedScreen(null);
              setSelectedIndicator(indicator);
            }}
            onSelectScreen={(screen) => {
              setSelectedIndicator(null);
              setSelectedScreen(screen);
            }}
            onChangePassword={() => {
              setSelectedScreen("alterarsenhapanel");
              setSelectedIndicator(null);
            }}            
            onLogoff={onLogoff}
          />
        </Col>
        <Col md={9} className="content-wrapper text-center">
          {!selectedScreen && !selectedIndicator && (
            <div>
              <Image
                src={empresaLogo}
                alt="Logotipo da Empresa"
                fluid
                style={{ maxWidth: "400px", marginBottom: "20px" }}
                onError={(e) => {
                  console.error("⚠️ Erro ao carregar imagem:", e.target.src);
                  e.target.src = "https://placehold.co/";
                }}
              />
              <h2 style={{ color: "#001d6b" }}>Bem-vindo(a), {userName}!</h2>
              <h4>Empresa: {empresaDisplayName}</h4>
            </div>
          )}
          {renderSelectedScreen()}
          {!selectedScreen && renderSelectedIndicator()}
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;
