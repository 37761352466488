import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function EsqueciSenhaPanel() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState({ text: '', type: '' });
    const navigate = useNavigate();

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;    

    const handleSubmit = async (event) => {
        event.preventDefault();
        setMessage({ text: '', type: '' }); // Resetar mensagens

        try {
            const response = await axios.post(`${API_BASE_URL}/api/auth/forgot-password`, { email });
            setMessage({ text: response.data.message, type: 'success' });

            // Redirecionar para a tela de verificação de código
            navigate('/verify-code', { state: { email } });
        } catch (error) {
            const errorMessage =
                error.response?.data?.message || 'Erro ao enviar o código. Tente novamente.';
            setMessage({ text: errorMessage, type: 'danger' });
        }
    };

    return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div style={{ width: '400px' }}>
                <h3 className="text-center">Esqueci a Senha</h3>
                {message.text && <Alert variant={message.type}>{message.text}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formEmail" className="mb-3">
                        <Form.Label>Digite seu e-mail</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Digite seu e-mail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit" className="w-100">
                        Enviar Código
                    </Button>
                </Form>
            </div>
        </div>
    );
}

export default EsqueciSenhaPanel;
