import React, { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col, Card, Button } from 'react-bootstrap';
import api from '../../config/api';
import debounce from 'lodash.debounce'; // Instale com "npm install lodash.debounce"

function MunicipiosInativosPanel() {
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [supervisor, setSupervisor] = useState('');
    const [supervisores, setSupervisores] = useState([]);
    const [municipiosData, setMunicipiosData] = useState({
        graph_pizza: null,
        graph_pareto_vendas: null,
        graph_pareto_kgs: null,
        total_municipios: 0,
        municipios_com_vendas: 0,
        municipios_inativos: 0
    });
    const [view, setView] = useState('geral');

    useEffect(() => {
        api.getSupervisores().then(data => {
            const sortedSupervisores = data.supervisores
                .filter(s => s)
                .sort((a, b) => a.localeCompare(b));
            setSupervisores(sortedSupervisores);
        });
    }, []);

    // Função para buscar dados apenas quando o usuário clicar no botão
    const fetchMunicipiosData = useCallback(debounce(async () => {
        if (dataInicio && dataFim) {
            try {
                console.log("Buscando dados com:", { dataInicio, dataFim, supervisor });
                const response = await api.get('/indicador/municipios_inativos', { params: { dataInicio, dataFim, supervisor } });
                console.log("Response data:", response.data);
                setMunicipiosData(response.data);
            } catch (error) {
                console.error('Erro ao buscar dados dos municípios inativos:', error);
            }
        }
    }, 500), [dataInicio, dataFim, supervisor]);

    return (
        <Card className="shadow-sm mb-4">
            <Card.Header as="h5">Municípios Inativos</Card.Header>
            <Card.Body>
                <Row className="mb-3">
                    <Col md={4}>
                        <Form.Group controlId="formDataInicio">
                            <Form.Label>Data Início</Form.Label>
                            <Form.Control
                                type="date"
                                value={dataInicio}
                                onChange={e => setDataInicio(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="formDataFim">
                            <Form.Label>Data Fim</Form.Label>
                            <Form.Control
                                type="date"
                                value={dataFim}
                                onChange={e => setDataFim(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="formSupervisor">
                            <Form.Label>Supervisor</Form.Label>
                            <Form.Control
                                as="select"
                                value={supervisor}
                                onChange={e => setSupervisor(e.target.value)}
                            >
                                <option value="">Selecione o Supervisor</option>
                                {supervisores.map(s => (
                                    <option key={s} value={s}>{s}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                {/* Botão para acionar a busca */}
                <Row className="mb-3 text-center">
                    <Col>
                        <Button variant="primary" onClick={fetchMunicipiosData}>
                            Buscar Dados
                        </Button>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={12}>
                        <Form.Check
                            inline
                            label="Geral"
                            name="view"
                            type="radio"
                            id="geral-radio"
                            checked={view === 'geral'}
                            onChange={() => setView('geral')}
                        />
                        <Form.Check
                            inline
                            label="Estratificação"
                            name="view"
                            type="radio"
                            id="estratificacao-radio"
                            checked={view === 'estratificacao'}
                            onChange={() => setView('estratificacao')}
                        />
                    </Col>
                </Row>

                {view === 'geral' && (
                    <>
                        <h5>Total de Municípios onde já houve vendas: {municipiosData.total_municipios}</h5>
                        <h6>Municípios com Vendas no Período: {municipiosData.municipios_com_vendas}</h6>
                        <h6>Municípios Inativos no Período: {municipiosData.municipios_inativos}</h6>
                        {municipiosData.graph_pizza && (
                            <div className="text-center">
                                <img
                                    src={`data:image/png;base64,${municipiosData.graph_pizza}`}
                                    alt="Distribuição de Municípios"
                                    className="img-fluid mt-3"
                                    style={{ maxWidth: '60%' }}
                                />
                            </div>
                        )}
                    </>
                )}

                {view === 'estratificacao' && (
                    <Row>
                        <Col md={6}>
                            {municipiosData.graph_pareto_vendas && (
                                <img
                                    src={`data:image/png;base64,${municipiosData.graph_pareto_vendas}`}
                                    alt="Municípios com Mais Vendas"
                                    className="img-fluid"
                                />
                            )}
                        </Col>
                        <Col md={6}>
                            {municipiosData.graph_pareto_kgs && (
                                <img
                                    src={`data:image/png;base64,${municipiosData.graph_pareto_kgs}`}
                                    alt="Municípios com Mais Kg Vendidos"
                                    className="img-fluid"
                                />
                            )}
                        </Col>
                    </Row>
                )}
            </Card.Body>
        </Card>
    );
}

export default MunicipiosInativosPanel;
