import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import api from '../../config/api';

function MargemLucro() {
    const [dataInicio, setDataInicio] = useState(''); // Estado para data de início
    const [dataFim, setDataFim] = useState(''); // Estado para data de fim
    const [supervisor, setSupervisor] = useState(''); // Estado para supervisor
    const [supervisores, setSupervisores] = useState([]); // Lista de supervisores
    const [graphUrl, setGraphUrl] = useState(null); // URL do gráfico
    const [margemLucroMedia, setMargemLucroMedia] = useState(0); // Margem de lucro média

    useEffect(() => {
        // Carregar supervisores do backend
        api.getSupervisores()
            .then(data => {
                const sortedSupervisores = data.supervisores
                    .filter(s => s !== null) // Ignorar valores nulos
                    .sort((a, b) => a.localeCompare(b)); // Ordenar alfabeticamente
                setSupervisores(sortedSupervisores);
            })
            .catch(error => {
                console.error("Erro ao carregar supervisores:", error);
            });
    }, []);

    const handleSearch = () => {
        if (!dataInicio || !dataFim) {
            alert("Por favor, selecione um período válido.");
            return;
        }

        api.getMargemLucro(dataInicio, dataFim, supervisor)
            .then(response => {
                setMargemLucroMedia(Number(response.margem_lucro_media) || 0);
                setGraphUrl(response.graph_url || null);
            })
            .catch(error => {
                console.error("Erro ao buscar margem de lucro:", error);
            });
    };

    return (
        <Card className="shadow-sm mb-4" style={{ border: 'none' }}>
            <Card.Header as="h5">
                Margem de Lucro
            </Card.Header>
            <Card.Body>
                <Row className="mb-3">
                    <Col md={4}>
                        <Form.Group controlId="formDataInicio">
                            <Form.Label>Data Início</Form.Label>
                            <Form.Control
                                type="date"
                                value={dataInicio}
                                onChange={e => setDataInicio(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="formDataFim">
                            <Form.Label>Data Fim</Form.Label>
                            <Form.Control
                                type="date"
                                value={dataFim}
                                onChange={e => setDataFim(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="formSupervisor">
                            <Form.Label>Supervisor</Form.Label>
                            <Form.Control
                                as="select"
                                value={supervisor}
                                onChange={e => setSupervisor(e.target.value)}
                            >
                                <option value="">Selecione o Supervisor</option>
                                {supervisores.map(s => (
                                    <option key={s} value={s}>{s}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Button variant="primary" onClick={handleSearch}>
                    Buscar Margem de Lucro
                </Button>
                {graphUrl && (
                    <div className="mt-4 text-center">
                        {/* <h5>Margem de Lucro Média: {margemLucroMedia.toFixed(2)}%</h5> */}
                        <img src={`data:image/png;base64,${graphUrl}`} alt="Gráfico de Margem de Lucro" className="img-fluid mx-auto d-block" />
                    </div>
                )}
            </Card.Body>
        </Card>
    );
}

export default MargemLucro;
