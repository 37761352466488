import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Form, Button, Alert } from 'react-bootstrap';

function VerificaCodigoPanel() {
    const [code, setCode] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const handleVerifyCode = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');

        try {
            const response = await axios.post(`${API_BASE_URL}/api/auth/verify-code`, {
                code,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setMessage('Código verificado com sucesso!');
            console.log('Redirecionando para redefinição de senha...');
            navigate(`/reset-password?email=${response.data.email}`); // Adiciona o e-mail ao URL
        } catch (err) {
            console.error('Erro ao verificar o código:', err.response?.data || err.message);
            setError(err.response?.data?.message || 'Erro ao verificar código.');
        }
    };

    return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div style={{ width: '400px' }}>
                <h3 className="text-center">Verificar Código</h3>
                {message && <Alert variant="success">{message}</Alert>}
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleVerifyCode}>
                    <Form.Group controlId="formCode" className="mb-3">
                        <Form.Label>Digite o código recebido no e-mail</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o código"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit" className="w-100">
                        Verificar Código
                    </Button>
                </Form>
            </div>
        </div>
    );
}

export default VerificaCodigoPanel;
