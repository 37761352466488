import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import axios from "axios";

function SelecionaEmpresa({ onEmpresaSelecionada }) {
  const [empresas, setEmpresas] = useState([]); // Estado para armazenar empresas
  const [selectedEmpresa, setSelectedEmpresa] = useState("");
  const [error, setError] = useState(""); // Estado para erros
  const navigate = useNavigate();

  useEffect(() => {
    // Função para buscar as empresas
    const fetchEmpresas = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/main/empresas`, // Verifique se o BASE_URL está correto
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Adiciona o token JWT
            },
          }
        );
        setEmpresas(response.data); // Armazena as empresas no estado
      } catch (error) {
        console.error("❌ Erro ao buscar empresas:", error);
        setError("Não foi possível carregar as empresas.");
      }
    };

    fetchEmpresas();
  }, []);

  const handleEmpresaSelecionada = () => {
    if (!selectedEmpresa) {
      console.error("❌ Nenhuma empresa selecionada.");
      return;
    }

    // Buscar empresa selecionada no array de empresas
    const empresaSelecionada = empresas.find(emp => emp.id.toString() === selectedEmpresa);

    if (empresaSelecionada) {
      localStorage.setItem("empresaId", empresaSelecionada.id);
      localStorage.setItem("empresaName", empresaSelecionada.name);
      console.log("✅ Empresa selecionada:", empresaSelecionada.name);

      // Atualiza o estado global
      onEmpresaSelecionada(empresaSelecionada.id, empresaSelecionada.name);

      // Redireciona para o dashboard
      navigate("/");
    } else {
      console.error("❌ Empresa selecionada não encontrada.");
    }
  };

  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <Row>
        <Col>
          <Card className="shadow p-3 mb-5 bg-white rounded" style={{ width: "400px" }}>
            <Card.Body>
              <Card.Title className="text-center mb-4">Escolha a Empresa</Card.Title>
              {error && <p className="text-danger text-center">{error}</p>}
              <Form>
                <Form.Group controlId="selectEmpresa" className="mb-3">
                  <Form.Label>Empresa</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedEmpresa}
                    onChange={(e) => setSelectedEmpresa(e.target.value)}
                  >
                    <option value="">Selecione uma empresa</option>
                    {empresas.map((empresa) => (
                      <option key={empresa.id} value={empresa.id}>
                        {empresa.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Button
                  variant="primary"
                  onClick={handleEmpresaSelecionada}
                  disabled={!selectedEmpresa}
                >
                  Continuar
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default SelecionaEmpresa;
